import { useEffect } from 'react';
import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import DiffPage from '../../differ/DiffPage';
import HomePage from '../../HomePage';
import { CalcPage } from '../../calculation/CalcPage';
import CryptoHome from '../../crypto/CryptoHome';
import ThreadHome from '../../crypto/ThreadHome';
import TextTransformsPage from '../../textTransforms/TextTransformsPage';
import TimerPage from '../../timers/TimerPage';
import WordleGamePage from '../../wordle/WordleGamePage';
import { ROUTES } from './routes';


const redirectRoot = () => <Redirect to={ROUTES.ROOT} />;

/**
 * AppRouter: a component that routes URLs to page components
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const AppRouter: React.FC = () => {
  const history = useHistory();

  useEffect(
    () => {
      const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[ 1 ];
      if (path) {
        history.replace(path);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ history.location ],
  );

  return (
    <Switch>
      <Route path={ROUTES.CALCULATORS0} component={CalcPage} />
      <Route path={ROUTES.CALCULATORS1} component={CalcPage} />
      <Route path={ROUTES.CALCULATORS2} component={CalcPage} />
      <Route path={ROUTES.THREAD} component={ThreadHome} />
      <Route path={ROUTES.CRYPTO} component={CryptoHome} />
      <Route path={ROUTES.TIMERS} component={TimerPage} />
      <Route path={ROUTES.TEXT_TRANSFORMS} component={TextTransformsPage} />
      <Route path={ROUTES.TEXT_TRANSFORMS0} component={TextTransformsPage} />
      <Route path={ROUTES.TEXT_TRANSFORMS1} component={TextTransformsPage} />
      <Route path={ROUTES.TEXT_DIFF} component={DiffPage} />
      <Route path={ROUTES.TEXT_DIFF0} component={DiffPage} />
      <Route path={ROUTES.GAME_WORDLE} component={WordleGamePage} />
      <Route path={ROUTES.ROOT} component={HomePage} />
      <Route path={'/'} render={redirectRoot} />
    </Switch>
  );
};
