import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import * as Formulas from './formulas';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, CLEAR_BTN, SOLUTION_TEXT, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root: css`
    flex-grow: 1;
  `,
};


type Props = {
  className?: string;
};

/**
 * SatisfactionCalculator: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const SatisfactionCalculator: React.FC<Props> = (props: Props) => {
  const [ raters, setRaters ]                   = React.useState<string | undefined>(undefined);
  const [ percentPositive, setPercentPositive ] = React.useState<string | undefined>(undefined);
  const [ pOfSatisfaction, setPOfSatisfaction ] = React.useState<string | undefined>(undefined);

  const onChangeRaters = useOnChange(setRaters);
  const onChangePercentPositive = useOnChange(setPercentPositive);

  const canCalculate = useAllGood([ raters, percentPositive ]);

  const onClear = () => {
    setRaters(undefined);
    setPercentPositive(undefined);
    setPOfSatisfaction(undefined);
  };

  const onSubmit = useOnSubmit(
    () => canCalculate && setPOfSatisfaction(`${Formulas.pOfSatisfactionGivenRating(+percentPositive!, +raters!)}`),
    [ raters, percentPositive, canCalculate, setPOfSatisfaction ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>P(Satisfaction | Rating)</h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>P(Satisfaction) = {pOfSatisfaction || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type='number' onChange={onChangeRaters} placeholder="n(Raters)" css={CALC_INPUT} />
          <Input type='number' onChange={onChangePercentPositive} placeholder='% Positive Ratings' css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type='reset' css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type='submit' css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
