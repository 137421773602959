import { css } from '@emotion/core';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { FORM_SPACING } from '../forms/styles';
import { diffLists } from './module';


const styles = {
  root:     [
    css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 20px;
    `,
    FORM_SPACING,
  ],
  evenRow:  css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 20px;
    
    & > div {
      flex: 1;
    }
  `,
  textAreaParent: css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
  `,
  textArea: css`
    display: flex;
    height: 33vh;
    border-radius: 10px;
    border-color: white;
    padding: 15px;
    font-size: 1.6rem;
  `,
  combobox: css`
    font-size: 1rem;
    padding: 5px 10px;
    width: 300px;
  `,
  results:  css`
    height: 35vh;
    border-radius: 10px;
    border-color: white;
    background-color: white;
    padding: 15px;
    font-size: 1.6rem;
    white-space: pre;
    overflow: auto;
  `,
};


type Props = {
  className?: string;
};


/**
 * TextTransformsPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const TextTransformsPage: React.FC<Props> = props => {
  const [ aText, setAText ] = useState<string>('');
  const [ bText, setBText ] = useState<string>('');

  const onChangeAText = useCallback(event => setAText(event.target.value), [ setAText ]);
  const onChangeBText = useCallback(event => setBText(event.target.value), [ setBText ]);

  const result = useMemo(
    () => {
      const aLines = aText.split('\n').map(line => line.trim()).filter(line => line.length > 0);
      const bLines = bText.split('\n').map(line => line.trim()).filter(line => line.length > 0);
      const diff = diffLists(aLines, bLines)
      return {
        uniqueA: diff.uniqueA.join('\n'),
        uniqueB: diff.uniqueB.join('\n'),
        common: diff.common.join('\n'),
      }
    },
    [aText, bText],
  );

  return (
    <div css={styles.root} className={props.className}>
      <div css={styles.evenRow}>
        <div css={styles.textAreaParent}>
          <h2>List A</h2>
          <textarea value={aText} onChange={onChangeAText} css={styles.textArea} />
        </div>
        <div css={styles.textAreaParent}>
          <h2>List B</h2>
          <textarea value={bText} onChange={onChangeBText} css={styles.textArea} />
        </div>
      </div>
      <div css={styles.evenRow}>
        <div>
          <h2>Unique to List A</h2>
          <div css={styles.results}>
            {result.uniqueA}
          </div>
        </div>
        <div>
          <h2>Unique to List B</h2>
          <div css={styles.results}>
            {result.uniqueB}
          </div>
        </div>
      </div>
      <div>
        <h2>Common to Both</h2>
        <div css={styles.results}>
          {result.common}
        </div>
      </div>
    </div>
  );
};

export default TextTransformsPage;
