import { mapChars } from '../lang/str';


const transformWhenDefined = (
  transformer: (str: string) => string,
) => (
  str: string,
) => (!str?.trim()) ? str : transformer(str);

export const randCaps = (text: string): string => mapChars(
  text,
  char => Math.random() < .5 ? char : char.toUpperCase(),
);


export const reverse = (text: string): string => text.split('').reverse().join('');


export const spaceToUnderscore = (text: string): string => text.split(' ').join('_');


export const allCaps = (sequence: string) => sequence.toLocaleUpperCase();
export const noCaps = (sequence: string) => sequence.toLocaleLowerCase();
export const capFirst = transformWhenDefined(
  (sequence: string) => allCaps(sequence[ 0 ]) + sequence.slice(1),
);

export const urlEncode = (text: string) => encodeURIComponent(text);
export const urlDecode = (text: string) => decodeURIComponent(text);

export const toBase64 = (text: string) => btoa(text);
export const fromBase64 = (text: string) => atob(text);

export const TITLE_CASE_EXEMPTIONS = [
  // Articles
  'a', 'an', 'the',

  // Conjunctions
  'for', 'and', 'nor', 'but', 'or', 'yet', 'so',

  // Prepositions
  'amid', 'at', 'as', 'atop', 'by', 'down', 'from', 'in', 'into', 'like', 'mid', 'near', 'next', 'of', 'off', 'on',
  'onto', 'out', 'over', 'pace', 'past', 'per', 'plus', 'pro', 'sans', 'save', 'than', 'till', 'to', 'unto', 'up',
  'upon', 'via', 'vice', 'vs', 'with',
];


export const titleCase = transformWhenDefined(
  (
    sequence: string,
  ) => capFirst(noCaps(sequence))
    .split(' ')
    .map(word => TITLE_CASE_EXEMPTIONS.indexOf(word) !== -1 ? word : capFirst(word))
    .join(' '),
);


export const jsonFormat = (sequence: string) => {
  return JSON.stringify(JSON.parse(sequence), null, 4);
};

export const xmlToJson = (xml: string) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  return JSON.stringify(xmlDoc, null, 4);
};

export const jsonToXml = (json: string) => {
  const obj = JSON.parse(json);
  return new XMLSerializer().serializeToString(obj);
};
