export const COLOR = {
  PAGE_BG:     '#EBECF0',
  SHADOW:      '#BABECC',
  RED:         '#AE1100',
  GREEN:       '#00AE11',
  BLUE:        '#009CAD',
  NEUTRAL_TXT: '#61677C',

  wordle: {
    bg:        '#1D425F',
    fg:        '#185B76',
    neutral:   '#F6E7C6',
    correct:   '#01C443',
    included:  '#FE9B24',
    incorrect: '#C44301',
  },
};
