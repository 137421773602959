import { css } from '@emotion/core';
import * as React from 'react';
import { COLOR } from '../core/colors';


const style = css`
  height: 52.5px;
  color: ${COLOR.NEUTRAL_TXT};
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  box-shadow:  inset 2px 2px 5px ${COLOR.SHADOW}, inset -5px -5px 10px #FFFFFF;
  
  border: 0;
  outline: 0;
  font-size: 16px;
  border-radius: 320px;
  padding: 16px;
  background-color: ${COLOR.PAGE_BG};
  text-shadow: 1px 1px 0 #FFFFFF;

  &:focus {
    box-shadow:  inset 1px 1px 2px ${COLOR.SHADOW}, inset -1px -1px 2px #FFFFFF;
  }
  
  
  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;


interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}


/**
 * Input: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const Input: React.FC<Props> = (props: Props) => (
  <input css={style} {...props} />
);
