import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import { pOfKSuccessInNTrials } from './formulas';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, CLEAR_BTN, SOLUTION_TEXT, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root: css`
    max-width: 605px;
  `,
};


type Props = {
  className?: string;
};

/**
 * POfSuccessesInTrials: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const POfSuccessesInTrials: React.FC<Props> = (props: Props) => {
  const [ nTrials, setNTrials ]       = React.useState<string | undefined>(undefined);
  const [ kSuccesses, setKSuccesses ] = React.useState<string | undefined>(undefined);
  const [ pOfSuccess, setPOfSuccess ] = React.useState<string | undefined>(undefined);
  const [ result, setResult ]         = React.useState<number | undefined>(undefined);

  const onChangeNTrials    = useOnChange(setNTrials);
  const onChangeKSuccesses = useOnChange(setKSuccesses);
  const onChangePofSuccess = useOnChange(setPOfSuccess);

  const canCalculate = useAllGood([ nTrials, kSuccesses, pOfSuccess ]);

  const onClear = () => {
    setNTrials(undefined);
    setKSuccesses(undefined);
    setPOfSuccess(undefined);
    setResult(undefined);
  };

  const onSubmit = useOnSubmit(
    () => canCalculate && setResult(pOfKSuccessInNTrials(+kSuccesses!, +nTrials!, +pOfSuccess!)),
    [ setResult, canCalculate, pOfKSuccessInNTrials, nTrials, kSuccesses, pOfSuccess ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>Binomial Probability Formula</h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>P(k successes | n trials) = {result || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type="number" onChange={onChangeNTrials} placeholder="n(trials)" css={CALC_INPUT} />
          <Input type="number" onChange={onChangeKSuccesses} placeholder="k successes" css={CALC_INPUT} />
          <Input type="number" onChange={onChangePofSuccess} placeholder="P(success)" css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type="reset" css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type="submit" css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
