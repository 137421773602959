import { css } from '@emotion/core';
import { useCallback } from 'react';
import * as React from 'react';
import { useKeyListener } from '../hooks/useKeyListener';


const letters = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
  'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
];

const styles = {
  root: css`
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #222222;
  `,
  key:  css`
    transition: all .25s ease;
    font-family: monospace !important;
    color: #FFFFFF;
    background-color: #444444;
    border: none;
    border-radius: 50%;
    padding: 5px 10px;
    margin: 5px 10px;

    @media (pointer: fine) {
      &:hover {
        cursor: pointer;
        background-color: #555555;
        border-radius: 0;
      }
    }
  `,
};


type Props = {
  onSelectLetter: (letter: string) => void;
  onSubmit: () => void;
  onClear: () => void;
  onBackspace: () => void;
  className?: string;
};


/**
 * Keyboard: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const Keyboard: React.FC<Props> = ({ onSelectLetter, onClear, onBackspace, onSubmit, className }) => {
  const onClickLetter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const letter = event.currentTarget.textContent;

      if (!letter) {
        return;
      }

      onSelectLetter(letter);
    },
    [ onSelectLetter ],
  );

  const onKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      console.log(event.key);
      switch (event.key) {
        case 'Backspace':
          onBackspace();
          break;
        case 'Enter':
          onSubmit();
          break;
        case 'Escape':
          onClear();
          break;
        default:
          break;
      }
    },
    [ onBackspace, onClear, onSubmit ],
  );

  useKeyListener(onKeyUp);

  return (
    <div css={styles.root} className={className}>
      {
        letters.map(letter => (<button key={letter} onClick={onClickLetter} css={styles.key}>{letter}</button>))
      }
      <button onClick={onBackspace} css={styles.key}>Del</button>
      <button onClick={onClear} css={styles.key}>Clear</button>
      <button onClick={onSubmit} css={styles.key}>Enter</button>
    </div>
  );
};

export default Keyboard;
