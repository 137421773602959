import { css } from '@emotion/core';
import * as React from 'react';
import { COLOR } from '../core/colors';


const style = css`
  color: ${COLOR.BLUE};
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #FFFFFF; 
  
  border: 0;
  outline: 0;
  border-radius: 320px;
  padding: 16px;
  background-color: ${COLOR.PAGE_BG};
  box-shadow: -5px -5px 20px #FFFFFF,  5px 5px 20px ${COLOR.SHADOW};
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: -2px -2px 5px #FFFFFF, 2px 2px 5px ${COLOR.SHADOW};
  }

  &:active {
    box-shadow: inset 1px 1px 2px ${COLOR.SHADOW}, inset -1px -1px 2px #FFFFFF;
  }
`;


interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}


/**
 * Button: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const Button: React.FC<Props> = (props: Props) => (
  <button {...props} css={style} />
);
