import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import * as Formulas from './formulas';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, CLEAR_BTN, SOLUTION_TEXT, SUBMIT_BTN, SUBTITLE, TITLE } from './styles';


const styles = {
  root: css`
    flex-grow: 1;
  `,
};


type Props = {
  className?: string;
};

/**
 * NChooseRCalculator: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const NChooseRCalculator: React.FC<Props> = (props: Props) => {
  const [ n, setN ]     = React.useState<string | undefined>(undefined);
  const [ r, setR ]     = React.useState<string | undefined>(undefined);
  const [ nCr, setNCr ] = React.useState<string | undefined>(undefined);

  const onChangeN = useOnChange(setN);
  const onChangeR = useOnChange(setR);

  const canCalculate = useAllGood([ n, r ]);

  const onClear = () => {
    setN(undefined);
    setR(undefined);
    setNCr(undefined);
  };

  const onSubmit = useOnSubmit(
    () => canCalculate && setNCr(`${Formulas.nCr(+n!, +r!)}`),
    [ n, r, canCalculate, setNCr ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>n Choose r <span css={SUBTITLE}>[a, b] = [b, a]</span></h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>nCr({n}, {r}) = {nCr || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type='number' onChange={onChangeN} placeholder='n' css={CALC_INPUT} />
          <Input type='number' onChange={onChangeR} placeholder='r' css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type='reset' css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type='submit' css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
