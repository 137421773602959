import { css } from '@emotion/core';
import * as React from 'react';
import { ButtonStyle } from '../core/styles/button';
import { FORM_SPACING_HORIZ_SM, FORM_SPACING_SM } from '../forms/styles';
import { BREAKPOINTS_PX } from '../layout/mediaQueries';
import { formatLong, toHours } from './module';


const styles = {
  root:   css`
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @media only screen and (min-width: ${BREAKPOINTS_PX.MOBILE_MEDIUM}px) {
      ${FORM_SPACING_HORIZ_SM};
    }

    @media only screen and (max-width: ${BREAKPOINTS_PX.MOBILE_MEDIUM}px) {
      flex-direction: column;
      align-items: stretch;
      ${FORM_SPACING_SM}
    }
  `,
  small:  css`
    flex-grow: 1;
    flex-basis: 1px;
  `,
  medium: css`
    flex-grow: 2;
    flex-basis: 1px;
  `,
  large:  css`
    flex-grow: 3;
    flex-basis: 1px;
  `,
};


type Props = {
  name: string;
  updateKey: string | number;
  elapsedSeconds: number;
  className?: string;
};


/**
 * TimerRow: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const TimerRow: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <span css={styles.large}>{props.name}</span>
    <span key={`${props.name}-${props.updateKey}-hrs`} css={styles.medium}>{toHours(props.elapsedSeconds)} hrs</span>
    <span key={`${props.name}-${props.updateKey}-long`} css={styles.medium}>{formatLong(props.elapsedSeconds)}</span>
    <button css={[ styles.small, ButtonStyle.Neutral ]}>Start/Stop</button>
    <button css={[ styles.small, ButtonStyle.Negative ]}>Delete</button>
  </div>
);

export default TimerRow;
