import { css } from '@emotion/core';
import * as React from 'react';
import { COLOR } from '../core/colors';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import { solveProportion } from './formulas';
import { useOnChange } from './hooks';
import { isGoodNumber, toNum } from './module';
import { BUTTON_ROW, CALC_INPUT, CLEAR_BTN, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root:           css`
    flex-grow: 1;
  `,
  inputContainer: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    
    @media only screen and (max-width: 515px) { 
      flex-direction: column;
      align-items: stretch;
    }
  `,
  inputGroup:     css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  input:          css`
    margin: 0;
  `,
  equalSign:      css`
    margin: 0 25px;
    padding-bottom: 10px; /* Compensate for padding in the font */
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: ${COLOR.NEUTRAL_TXT};
  `,
  fractionBar:    css`
    flex-grow: 1;
    color: ${COLOR.NEUTRAL_TXT};
    border-style: solid;
    margin: 20px 0;
  `,
};


type Props = {
  className?: string;
};


/**
 * ProportionSolver: a component that renders a form for computing the missing variable in a proportion equality
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const ProportionSolver: React.FC<Props> = (props: Props) => {
  const [ a, setA ] = React.useState<string | undefined>(undefined);
  const [ b, setB ] = React.useState<string | undefined>(undefined);
  const [ c, setC ] = React.useState<string | undefined>(undefined);
  const [ d, setD ] = React.useState<string | undefined>(undefined);

  const onChangeA = useOnChange(setA);
  const onChangeB = useOnChange(setB);
  const onChangeC = useOnChange(setC);
  const onChangeD = useOnChange(setD);

  const setMissing = React.useCallback(
    (value: string | number) => {
      if (!isGoodNumber(a)) {
        setA(`${value}`);
      } else if (!isGoodNumber(b)) {
        setB(`${value}`);
      } else if (!isGoodNumber(c)) {
        setC(`${value}`);
      } else if (!isGoodNumber(d)) {
        setD(`${value}`);
      }
    },
    [ setA, setB, setC, setD, a, b, c, d ],
  );

  const canCalculate = React.useMemo(
    () => [ a, b, c, d ].reduce((running, next) => next === '' || next === undefined ? running + 1 : running, 0) === 1,
    [ a, b, c, d ],
  );

  const onClear = React.useCallback(
    () => {
      setA(undefined);
      setB(undefined);
      setC(undefined);
      setD(undefined);
    },
    [ setA, setB, setC, setD ],
  );

  const onSubmit = useOnSubmit(
    () => {
      if (!canCalculate) {
        setMissing('NaN');
      }

      setMissing(solveProportion(toNum(a), toNum(b), toNum(c), toNum(d)) ?? '');
    },
    [ canCalculate, setMissing, a, b, c, d ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>Proportion Solver</h4>

        <div css={styles.inputContainer}>
          <div css={styles.inputGroup}>
            <Input
              type='number'
              value={a}
              onChange={onChangeA}
              placeholder='A'
              css={[ CALC_INPUT, styles.input ]}
            />
            <hr css={styles.fractionBar} />
            <Input
              type='number'
              value={b}
              onChange={onChangeB}
              placeholder='B'
              css={[ CALC_INPUT, styles.input ]}
            />
          </div>
          <span css={styles.equalSign}>=</span>
          <div css={styles.inputGroup}>
            <Input
              type='number'
              value={c}
              onChange={onChangeC}
              placeholder='C'
              css={[ CALC_INPUT, styles.input ]}
            />
            <hr css={styles.fractionBar} />
            <Input
              type='number'
              value={d}
              onChange={onChangeD}
              placeholder='D'
              css={[ CALC_INPUT, styles.input ]}
            />
          </div>
        </div>

        <div css={BUTTON_ROW}>
          <Button type='reset' css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type='submit' css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
