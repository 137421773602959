import { css } from '@emotion/core';
import { COLOR } from '../colors';


export const UNSELECTABLE_TEXT = css`
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;


export const DROP_SHADOW = css`
  box-shadow: 0 4px 10px 2px rgba(0,0,0,0.25);
`;

export const INSET = css`
  color: ${COLOR.SHADOW};
  text-shadow: 1px 1px 1px #FFFFFF;
  font-weight: bold;
`;
