import { css } from '@emotion/core';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { FORM_SPACING } from '../forms/styles';
import {
  allCaps, fromBase64, jsonFormat, jsonToXml,
  noCaps,
  randCaps,
  reverse,
  spaceToUnderscore,
  titleCase,
  toBase64,
  urlDecode,
  urlEncode, xmlToJson,
} from './module';


const styles = {
  root:     [
    css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px;
    `,
    FORM_SPACING,
  ],
  textArea: css`
    height: 35vh;
    border-radius: 10px;
    border-color: white;
    padding: 15px;
    font-size: 1.6rem;
  `,
  combobox: css`
    font-size: 1rem;
    padding: 5px 10px;
    width: 300px;
  `,
  results:  css`
    height: 35vh;
    border-radius: 10px;
    border-color: white;
    background-color: white;
    padding: 15px;
    font-size: 1.6rem;
    white-space: pre;
  `,
};


type Props = {
  className?: string;
};


const transforms: { [ key: string ]: (text: string) => string } = {
  'Random Caps':         randCaps,
  'URL Encode':          urlEncode,
  'URL Decode':          urlDecode,
  'Base64 Encode':       toBase64,
  'Base64 Decode':       fromBase64,
  'Reverse':             reverse,
  'Space to Underscore': spaceToUnderscore,
  'Title Case':          titleCase,
  'All Caps':            allCaps,
  'No Caps':             noCaps,
  'Format JSON':         jsonFormat,
  'XML to JSON':         xmlToJson,
  'JSON to XML':         jsonToXml,
};


/**
 * TextTransformsPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const TextTransformsPage: React.FC<Props> = props => {
  const [ selectedTransform, setSelectedTransform ] = useState<string>(Object.getOwnPropertyNames(transforms)[ 0 ]);
  const onSelectTransform = useCallback(event => setSelectedTransform(event.target.value), [ setSelectedTransform ]);

  const [ text, setText ] = useState<string>('');
  const onChangeText = useCallback(event => setText(event.target.value), [ setText ]);

  const result = useMemo(
    () => {
      try {
        return transforms[ selectedTransform ](text);
      } catch (e) {
        return text;
      }
    },
    [ text, selectedTransform ],
  );

  return (
    <div css={styles.root} className={props.className}>
      <textarea value={text} onChange={onChangeText} css={styles.textArea} />
      <select value={selectedTransform} onChange={onSelectTransform} css={styles.combobox}>
        {
          Object
            .getOwnPropertyNames(transforms)
            .map(transform => <option id={transform} key={transform} value={transform}>{transform}</option>)
        }
      </select>
      <div css={styles.results}>
        {result}
      </div>
    </div>
  );
};

export default TextTransformsPage;
