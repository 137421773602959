/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../core/routing/routes';
import { BUTTON } from '../forms/styles';


const styles = {
  bigButton: css`
    line-height: 60px;
    font-size: 2.0rem;
    ${BUTTON};
  `,
};


/**
 * CryptoHome: a component that
 *
 * PureComponent: true
 * Connected to Redux: false
 */
export default class CryptoHome extends React.PureComponent {
  public render = () => (
    <div css={css`
           height: 100%;
           display: flex;
           flex-direction: column;
           align-items: stretch;
           justify-content: center;
         `}>
      <div css={css`
             display: flex;
             flex-direction: row;
             align-items: center;
             justify-content: space-around;
           `}>
        <div css={css`
             display: flex;
             flex-direction: column;
           `}>
          <span css={css`font-size: 2.0rem;`}>Find Thread</span>
          <input css={css`
                   width: 350px;
                   height: 40px;
                   font-size: 1.4rem;
                 `}/>
        </div>
        <Link to={ROUTES.THREAD} css={styles.bigButton}>New Thread</Link>
        <Link to={''} css={styles.bigButton}>Generate Keys</Link>

      </div>
    </div>
  );
}
