export const mapChars = (str: string, mapFunc: (char: string) => string): string => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += mapFunc(str.charAt(i));
  }
  return result;
};


export const mapCharsTo = <T>(str: string, mapFunc: (char: string) => T): T[] => {
  let result: T[] = [];
  for (let i = 0; i < str.length; i++) {
    result = result.concat(mapFunc(str.charAt(i)));
  }
  return result;
};

