import * as R from 'ramda';


export const isGoodNumber = (n: string | undefined): boolean => n !== undefined && n !== '' && !Number.isNaN(+n);


export const allGood = (numbers: Array<string | undefined>): boolean => R.all(isGoodNumber, numbers);


export const toNum = (n: string | null | undefined): number | undefined => {
  if (n === '' || n === undefined || n === null || Number.isNaN(+n)) {
    return undefined;
  }

  return +n;
};
