import * as React from 'react';

export const useOnSubmit = (
  callback: (event: any) => void,
  deps: any[],
): ((event: any) => void) => React.useCallback(
  event => {
    event.preventDefault();
    callback(event);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [callback, ...deps],
);
