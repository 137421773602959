const ROOT   = process.env.PUBLIC_URL || '';
const CRYPTO = ROOT + '/crypto';

export const ROUTES = {
  ROOT:         ROOT,

  // Calculators
  CALCULATORS0: ROOT + '/calc',
  CALCULATORS1: ROOT + '/calculator',
  CALCULATORS2: ROOT + '/calculators',

  // Crypto
  CRYPTO:       ROOT + '/crypto',
  THREAD:       CRYPTO + '/thread',

  // Timers
  TIMERS: ROOT + '/timers',

  // Text
  TEXT_TRANSFORMS: ROOT + '/text-transform',
  TEXT_TRANSFORMS0: ROOT + '/text',
  TEXT_TRANSFORMS1: ROOT + '/str',

  // Differ
  TEXT_DIFF: ROOT + '/text-diff',
  TEXT_DIFF0: ROOT + '/diff',

  // Games
  GAME_WORDLE: ROOT + '/games/wordle',
};
