import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, SOLUTION_TEXT, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root: css`
    flex-grow: 1;
  `,
};


const getRand = (min: number, max: number): number => Math.round((Math.random() * (max - min)) + min);


type Props = {
  className?: string;
};

/**
 * RandomNumberGenerator: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const RandomNumberGenerator: React.FC<Props> = (props: Props) => {
  const [ min, setMin ]                 = React.useState<string>('0');
  const [ max, setMax ]                 = React.useState<string>('1');
  const [ nextRandNum, setNextRandNum ] = React.useState<string>(`${getRand(0, 1)}`);

  const onChangeMin = useOnChange(setMin);
  const onChangeMax = useOnChange(setMax);

  const canCalculate = useAllGood([ min, max ]);

  const onSubmit = useOnSubmit(
    () => canCalculate && setNextRandNum(`${getRand(+`${min}`, +`${max}`)}`),
    [ min, max, canCalculate, setNextRandNum ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>Random Number</h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>{nextRandNum || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type="number" onChange={onChangeMin} placeholder="Min" value={min} css={CALC_INPUT} />
          <Input type="number" onChange={onChangeMax} placeholder="Max" value={max} css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type="submit" css={SUBMIT_BTN} onClick={onSubmit}>Generate</Button>
        </div>
      </form>
    </Plateau>
  );
};
