export default {
  LIGHT: {
    // someBlue:       '#47677D',
    TEXT:            '#141618',
    BACKGROUND:      '#E4ECF1',
    BUTTON_BG:       '#88AAC2',
    BUTTON_BG_HOVER: '#ABC3D4',
    BUTTON_TEXT:     '#141618',
    INPUT_BORDER:    '#88AAC2',
  },
  DARK:  {
    TEXT:            '#FFFFFF',
    BACKGROUND:      '#141618',
    BUTTON_BG:       '#ABC3D4',
    BUTTON_BG_HOVER: '#88AAC2',
    BUTTON_TEXT:     '#141618',
    INPUT_BORDER:    '#ABC3D4',
  },
};
