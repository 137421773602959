import { css } from '@emotion/core';
import { motion } from 'framer-motion';
import React from 'react';
import githubImg from './assets/github.svg';
import linkedIn from './assets/linkedIn.svg';
import wordle from './assets/wordle.svg';
import { randSign } from './lang/math';
import bg from './assets/bg_green_gradient.svg';


const Mist = '#90AFC5';
const Stone = '#336B87';
const Shadow = '#2A3132';
const Autumn = '#763626';


const links = {
  github: 'https://www.github.com/kellenlask',
  linked: 'https://www.linkedin.com/in/kellenlask/',
  resume: '/Resume.pdf',
  wordle: '/games/wordle'
};


const linkAnimation = () => ({
  initial:    { scale: 0, opacity: 0 },
  animate:    { scale: 1, opacity: 1 },
  whileHover: { scale: 1.2, rotate: 10 * randSign() },
  transition: {
    type:      'spring',
    stiffness: 260,
    damping:   20,
  },
});


const styles = {
  root:      css`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: url(${bg});
    //background: rgb(255, 255, 255);
    //background: linear-gradient(180deg, #FFFFFF 45%, ${Mist} 100%);
    //background: radial-gradient(at bottom right, #FFFFFF, ${Autumn}, #FFFFFF); 
    overflow-y: auto;
  `,
  pageTitle: css`
    margin: 20px 25px;
    padding: 0;
    color: ${Shadow};
  `,
  links:     css`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
  linkDiv:   css`
    width: 200px;
    height: 200px;
    margin: 30px;
    //background: ${Stone};
    background-color: ${Shadow};;
    border-radius: 10px;
    box-shadow: 1px 1px 10px ${Shadow};
    //border: 5px solid ${Mist};
    user-select: none;
  `,
  link:      css`
    display: block;
    width: 100%;
    height: 100%;
  `,
  linkImg:   css`
    width: 80%;
    height: 80%;
    margin: 10%;
  `,
};


const HomePage = () => (
  <div css={styles.root}>
    <h1 css={styles.pageTitle}>Kellen Lask</h1>
    <div css={styles.links}>
      <motion.div {...linkAnimation()} css={styles.linkDiv}>
        <a href={links.github} target="_blank" rel="noopener noreferrer" css={styles.link}>
          <img src={githubImg} alt="Github" css={styles.linkImg} />
        </a>
      </motion.div>

      <motion.div {...linkAnimation()} css={styles.linkDiv}>
        <a href={links.linked} target="_blank" rel="noopener noreferrer" css={styles.link}>
          <img src={linkedIn} alt="LinkedIn" css={styles.linkImg} />
        </a>
      </motion.div>

      {/*<motion.div {...linkAnimation()} css={styles.linkDiv}>*/}
      {/*  <a href={links.resume} target="_blank" rel="noopener noreferrer" css={styles.link}>*/}
      {/*    <img src={resume} alt="Resume" css={styles.linkImg} />*/}
      {/*  </a>*/}
      {/*</motion.div>*/}

      <motion.div {...linkAnimation()} css={styles.linkDiv}>
        <a href={links.wordle} css={styles.link}>
          <img src={wordle} alt="Wordle Clone" css={styles.linkImg} />
        </a>
      </motion.div>
    </div>
  </div>
);

export default HomePage;
