import { css } from '@emotion/core';


const btnBase = css`
  transition: opacity .25s ease;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  
  &:hover { 
    opacity: .5;
    cursor: pointer;
  }
`;

export const ButtonStyle = {
  Positive: css`
    background-color: #00AE11;
    color: white;
    ${btnBase};
  `,
  Neutral: css`
    background-color: #1100AE;
    color: white;
    ${btnBase};
  `,
  Negative: css`
    background-color: #AE1100;
    color: white;
    ${btnBase};
  `,
};
