import { css } from '@emotion/core';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import words from '../assets/en-dict.json';
import { COLOR } from '../core/colors';
import { useEventCallback } from '../hooks/useEventCallback';
import Modal from '../layout/Modal';
import Keyboard from './Keyboard';
import WordleAttempt from './WordleAttempt';


const styles = {
  root:                css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    background-color: ${COLOR.wordle.bg};
  `,
  gameHeader:          css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
  `,
  gameControls:        css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    padding: 20px;
  `,
  gameControl:         css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${COLOR.wordle.neutral};

    input {
      width: 50px;
      padding: 5px 10px;
      border: none;
      color: ${COLOR.wordle.neutral};
      background-color: ${COLOR.wordle.fg};
    }
  `,
  tryStatus:           css`
    color: ${COLOR.wordle.neutral};
  `,
  userMsgModal:        css`
    background-color: ${COLOR.wordle.neutral};
  `,
  userMsgModalContent: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  `,
  keyboard:            css`
    box-shadow: 1px 1px 3px #00000066;
  `,
  attempts:            css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 15px;
    padding: 20px;
    overflow-y: auto;
  `,
  btn:                 css`
    transition: all .25s ease;
    color: #FFFFFF;
    background-color: ${COLOR.RED};
    border: none;
    border-radius: 50%;
    padding: 10px 20px;

    @media (pointer: fine) {
      &:hover {
        cursor: pointer;
        border-radius: 0;
      }
    }
  `,
};

type Props = {
  className?: string;
};


/**
 * Wordle: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const Wordle: React.FC<Props> = ({ className }) => {
  const [ chances, setChances ] = useState(6);
  const [ wordLength, setWordLength ] = useState(5);

  const wordList = useMemo(() => Object.keys(words).filter(word => word.length === wordLength), [ wordLength ]);

  const [ userMsg, setUserMsg ] = useState('');
  const [ word, setWord ] = useState('');
  const [ tries, setTries ] = useState<string[]>([]);
  const [ currentTry, setCurrentTry ] = useState('');

  const letterStyle = useMemo(
    () => css`
      width: calc((100vw - 20px) / ${word.length} - 15px);
      height: calc((100vw - 20px) / ${word.length} - 15px);
      line-height: min(calc((100vw - 20px) / ${word.length} - 15px), 135px);
      font-size: min(calc((100vw - 20px) / ${word.length} - 40px), 110px);
    `,
    [ word.length ],
  );

  const pickRandWord = useCallback(() => wordList[ Math.floor(Math.random() * wordList.length) ], [ wordList ]);

  const resetGame = useCallback(
    () => {
      setWord(pickRandWord());
      setTries([]);
      setCurrentTry('');
    },
    [ pickRandWord ],
  );

  useEffect(resetGame, [ wordList ]);

  const onChangeChances = useCallback((event) => setChances(+event.target.value), []);
  const onChangeWordLength = useCallback((event) => setWordLength(+event.target.value), []);

  const clearUserMsg = useCallback(
    () => {
      setUserMsg('');

      if (tries.length === chances || tries[ 0 ] === word) {
        resetGame();
      }
    },
    [ chances, resetGame, tries, word ],
  );

  const onClear = useCallback(() => setCurrentTry(''), []);
  const onBackspace = useEventCallback(() => setCurrentTry(currentTry.slice(0, currentTry.length - 1)));
  const onSelectLetter = useEventCallback(
    (letter: string) => setCurrentTry((currentTry + letter).slice(0, Math.min(word.length, currentTry.length + 1))),
  );

  const onSubmitTry = useEventCallback(
    () => {
      // If the word isn't in the dictionary or it isn't the correct length, message user
      if (currentTry.length !== wordLength || !(words as any)[ currentTry ]) {
        setUserMsg(`${currentTry} is not in the dictionary.`);
        return;
      }

      setTries([ currentTry ].concat(tries));
      setCurrentTry('');

      if (currentTry === word) {
        setUserMsg(`You got ${currentTry} in ${tries.length + 1} attempt${tries.length > 0 ? 's' : ''}!`);
        return;
      }

      if (tries.length + 1 === chances) {
        setUserMsg(`You ran out of attempts! The word was ${word}.`);
      }
    },
  );

  return (
    <div css={styles.root} className={className}>
      <div css={styles.gameHeader}>
        <button onClick={resetGame} css={styles.btn}>Reset Game</button>
        <span css={styles.tryStatus}>{tries.length} / {chances}</span>
      </div>
      <div css={styles.gameControls}>
        <label css={styles.gameControl}>
          Word Length
          <input
            type="number"
            placeholder="Word Length"
            min={2}
            max={25}
            value={wordLength}
            onChange={onChangeWordLength}
          />
        </label>
        <label css={styles.gameControl}>
          Tries
          <input type="number" placeholder="Tries" min={1} max={10} value={chances} onChange={onChangeChances} />
        </label>
      </div>
      <div css={styles.attempts}>
        {
          tries.length < chances && (
            <WordleAttempt
              word={word}
              attempt={currentTry}
              showCorrections={false}
              letterStyle={letterStyle}
            />
          )
        }
        {
          tries.map(attempt => (
            <WordleAttempt
              key={attempt}
              word={word}
              attempt={attempt}
              showCorrections={true}
              letterStyle={letterStyle}
            />
          ))
        }
      </div>
      <Keyboard
        onSelectLetter={onSelectLetter}
        onClear={onClear}
        onBackspace={onBackspace}
        onSubmit={onSubmitTry}
        css={styles.keyboard}
      />
      {
        userMsg && (
          <Modal css={styles.userMsgModal} onDismiss={clearUserMsg}>
            <div css={styles.userMsgModalContent}>{userMsg}</div>
          </Modal>
        )
      }
    </div>
  );
};

export default Wordle;
