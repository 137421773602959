import { useCallback, useLayoutEffect, useRef } from 'react';


/**
 * Wraps a callback function in a ref and returns a new callback function that calls that ref's current value to avoid
 * triggering unnecessary renders.
 */
export const useEventCallback = <T extends (...args: any[]) => any>(fn: T) => {
  let ref = useRef<T>(fn);

  useLayoutEffect(() => { ref.current = fn; });

  return useCallback(
    (...args) => ref.current(...args),
    [],
  );
};
