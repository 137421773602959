import { css } from '@emotion/core';
import { COLOR } from '../core/colors';
import { FORM_SPACING_HORIZ } from '../forms/styles';


export const TITLE = css`
  font-weight: bold;
  margin-top: 0;
  color: ${COLOR.NEUTRAL_TXT};
`;

export const SUBTITLE = css`
  font-weight: normal;
  font-size: 10px;
  margin-top: 0;
  color: ${COLOR.NEUTRAL_TXT};
`;

export const SOLUTION_TEXT = css`
  font-weight: bold;
  color: ${COLOR.NEUTRAL_TXT};
`;

export const CALC_INPUTS = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-right: -25px;
`;

export const CALC_INPUT = css`
  flex-grow: 1;
  margin-bottom: 25px;
  margin-right: 25px;
`;

export const BUTTON_ROW = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  ${FORM_SPACING_HORIZ};
`;

export const SUBMIT_BTN = css`
  color: ${COLOR.BLUE};
`;

export const CLEAR_BTN = css`
  color: ${COLOR.RED};
`;
