import { css, SerializedStyles } from '@emotion/core';
import { useMemo } from 'react';
import * as React from 'react';
import { COLOR } from '../core/colors';


const styles = {
  root:           css`
    display: flex;
    flex-direction: row;
    gap: 15px;
  `,
  letterBox:      css`
    max-width: 150px;
    max-height: 150px;
    font-weight: bold;
    text-align: center;
    background-color: ${COLOR.wordle.fg};
    box-shadow: 1px 1px 3px #00000066;
  `,
  correctLetter:  css`color: ${COLOR.GREEN};`,
  includedLetter: css`color: ${COLOR.RED};`,
};


const getColor = (
  showCorrections: boolean,
  word: string,
  attempt: string | undefined,
  index: number,
): string => {
  if (!showCorrections) {
    return COLOR.wordle.neutral;
  }

  const correct = word[ index ];
  const given = attempt?.[ index ];

  if (correct === given) {
    return COLOR.wordle.correct;
  }

  if (given && word.includes(given)) {
    return COLOR.wordle.included;
  }

  return COLOR.wordle.incorrect;
};


type Props = {
  word: string;
  attempt: string | undefined;
  showCorrections: boolean;
  letterStyle?: SerializedStyles;
  className?: string;
};

/**
 * WordleAttempt: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const WordleAttempt: React.FC<Props> = ({ word, attempt, showCorrections, letterStyle, className }) => {
  const letters = useMemo(() => word.split(''), [ word ]);

  return (
    <div css={styles.root} className={className}>
      {
        letters.map((letter, i) => (
          <span
            key={`${i}-${letter}`}
            css={[
              styles.letterBox,
              letterStyle,
              css`color: ${getColor(showCorrections, word, attempt, i)}`,
            ]}
          >
            {attempt?.[ i ] || ''}
          </span>
        ))
      }
    </div>
  );
};

export default WordleAttempt;
