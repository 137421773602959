/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import * as React from 'react';


/**
 * LabelGroup: a component that
 *
 * PureComponent: true
 * Connected to Redux: false
 */
export default class LabelGroup extends React.PureComponent<{
  label: string;
  className?: string;
  children: React.ReactNode;
}> {
  public render = () => (
    <label css={css`
             display: flex;
             flex-direction: column;
             font-size: 1.6rem;
             font-weight: bold;
             ${this.props.className};
           `}>
      {this.props.label}
      {this.props.children}
    </label>
  );
}
