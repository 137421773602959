import { css } from '@emotion/core';
import * as React from 'react';
import Wordle from './Wordle';


const styles = {
  root:         css`
    height: 100vh;
  `,
};

type Props = {
  className?: string;
};


/**
 * WordleGamePage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const WordleGamePage: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <Wordle />
  </div>
);

export default WordleGamePage;
