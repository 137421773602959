import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import * as Formulas from './formulas';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, CLEAR_BTN, SOLUTION_TEXT, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root: css`
    flex-grow: 1;
  `,
};


type Props = {
  className?: string;
};

/**
 * IterationsToCalculator: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const IterationsToCalculator: React.FC<Props> = (props: Props) => {
  const [ p, setP ]                   = React.useState<string | undefined>(undefined);
  const [ pOfE, setPOfE ]             = React.useState<string | undefined>(undefined);
  const [ iterations, setIterations ] = React.useState<string | undefined>(undefined);

  const onChangeP    = useOnChange(setP);
  const onChangePOfE = useOnChange(setPOfE);

  const canCalculate = useAllGood([ p, pOfE ]);

  const onClear = () => {
    setP(undefined);
    setPOfE(undefined);
    setIterations(undefined);
  };

  const onSubmit = useOnSubmit(
    () => canCalculate && setIterations(`${Formulas.iterationsToP(+p!, +pOfE!)}`),
    [ p, pOfE, canCalculate, setIterations ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>Samplings to Yield P'(E)</h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>Samplings = {iterations || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type='number' onChange={onChangeP} placeholder="P'" css={CALC_INPUT} />
          <Input type='number' onChange={onChangePOfE} placeholder='P(E)' css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type='reset' css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type='submit' css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
