import { css } from '@emotion/core';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { ButtonStyle } from '../core/styles/button';
import { useInterval } from '../core/useInterval';
import Modal from '../layout/Modal';
import { calcTotalElapsed } from './module';
import TimerRow from './TimerRow';
import { Timer } from './timers';

const styles = {
  root:          css`
    display: flex;
    flex-direction: column;
    padding: 25px;
    
    & > div:nth-child(odd) {
      background-color: #CCCCCC;
    }
    
    & > div:nth-child(even) {
      background-color: #DDDDDD;
    }
  `,
  addTimerBtn: css`
    margin-top: 25px;
    align-self: flex-start;
  `,
  addTimerModal: {
    root:    css`
    `,
    form:    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    `,
    input:   css`
      flex-grow: 1;
      margin-right: 25px;
      padding: 5px;
    `,
    saveBtn: css`
      padding: 5px 20px;
    `,
  },
};


type Props = {
  className?: string;
};


/**
 * TimerPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const TimerPage: React.FC<Props> = props => {
  const [ showNameModal, setShowNameModal ] = useState<boolean>(true);
  const [ timers, setTimers ] = useState<Timer[]>([]);
  const [ name, setName ] = useState('');
  const [ key, setKey ] = useState(new Date().getTime());

  const updateKey = useCallback(() => setKey(new Date().getTime()), [ setKey ]);

  useInterval(updateKey, 1000);

  const addTimer = useCallback(
    (name: string) => {
      setTimers(timers.concat(
        {
          name,
          activeInstance: { start: new Date() },
          instances:      [],
        },
      ));
    },
    [ timers, setTimers ],
  );

  const removeTimer = useCallback(
    (name: string) => setTimers(timers.filter(timer => timer.name !== name)),
    [ timers, setTimers ],
  );

  useEffect(
    () => addTimer('TestTimer'),
    // eslint-disable-next-line
    [],
  );

  const onNameChange = useCallback(
    (event) => {
      const value = event.target.value;
      setName(value);
    },
    [ setName ],
  );

  const onCancelAdd = useCallback(() => setShowNameModal(false), [ setShowNameModal ]);
  const onAddTimer = useCallback(() => setShowNameModal(true), [ setShowNameModal ]);
  const onNameTimer = useCallback(
    (event) => {
      event.preventDefault();

      addTimer(name);
      setName('');

      setShowNameModal(false);
    },
    [ addTimer, name, setName ],
  );

  return (
    <>
      <div css={[styles.root]} className={props.className}>
        {
          timers.map(timer => (
            <TimerRow
              key={timer.name}
              name={timer.name}
              elapsedSeconds={calcTotalElapsed(timer)}
              updateKey={key}
            />
          ))
        }
        <button css={[ButtonStyle.Positive, styles.addTimerBtn]} onClick={onAddTimer}>Add Timer</button>
      </div>
      {
        showNameModal && (
          <Modal onDismiss={onCancelAdd} css={styles.addTimerModal.root}>
            <form onSubmit={onNameTimer} css={styles.addTimerModal.form}>
              <input type="text" autoFocus={true} onChange={onNameChange} css={styles.addTimerModal.input} />
              <button type="submit" css={[styles.addTimerModal.saveBtn, ButtonStyle.Positive]}>Save</button>
            </form>
          </Modal>
        )
      }
    </>
  );
};

export default TimerPage;
