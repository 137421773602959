import { useEffect, useRef } from 'react';


export const useInterval = (callback: () => void, delayMs: number): void => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {savedCallback.current = callback;}, [ callback ]);

  // Set up the interval.
  useEffect(
    () => {
      const tick = () => savedCallback.current && savedCallback.current();
      const id   = setInterval(tick, delayMs);

      // Return cleanup func to React
      return () => clearInterval(id);
    },
    [ delayMs ],
  );
};
