import Algebrite from 'algebrite';


export const fac = (n: number): number => n <= 0 ? 1 : n * fac(n - 1);

/**
 * Calculates the number of samplings required to have k probability of an event E with likelihood P(E) occurring.
 * n = ln(1 - k) / ln(1 - P(E))
 * @param k - The control probability, this is the probability you're targeting
 * @param pOfE - The probability (0 - 1) that event E wil occur
 */
export const iterationsToP = (
  k: number,
  pOfE: number,
): number => Math.log(1 - k) / Math.log(1 - pOfE);

/**
 * Bayes Theorem:
 * @param pOfA
 * @param pOfB
 * @param pOfBGivenA
 */
export const bayesTheorem = (
  pOfA: number,
  pOfB: number,
  pOfBGivenA: number,
): number => (pOfBGivenA * pOfA) / pOfB;

/**
 * "n choose r" for when 2 samples including the same elements in a different order are equivalent.
 * @param n - number of elements
 * @param r - sample size
 */
export const nCr = (
  n: number,
  r: number,
): number => fac(n) / (fac(r) * fac(n - r));

/**
 * "n permute r" for when 2 samples including the same elements in a different order are not equivalent.
 * @param n - number of elements
 * @param r - sample size
 */
export const nPr = (
  n: number,
  r: number,
): number => fac(n) / fac(n - r);

/**
 * a / b = c / d where ONE of the arguments is undefined.
 */
export const solveProportion = (
  a: number | undefined,
  b: number | undefined,
  c: number | undefined,
  d: number | undefined,
): number | undefined => {
  if (a === undefined) { return b! * c! / d!; }
  if (b === undefined) { return a! * d! / c!; }
  if (c === undefined) { return a! * d! / b!; }
  if (d === undefined) { return b! * c! / a!; }
  return undefined;
};

/**
 * Laplace's rule of succession - calculates the likelihood of a positive next sample given an existing rating.
 * @param percentPositive - value between 0 and 1
 * @param numberOfRaters - integer
 */
export const pOfSatisfactionGivenRating = (
  percentPositive: number,
  numberOfRaters: number,
): number => (percentPositive * numberOfRaters + 1) / (numberOfRaters + 2);


/**
 * Calculates the likelihood of getting k successes from n trials given P(success)
 * E.g. calculating the probability of getting 5 heads from 10 coin flips given P(heads) = .5
 * @param kSuccesses - The number of successes you're looking for
 * @param nTrials - The number samplings/trials you're looking for success on
 * @param pOfSuccess - value between 0 and 1 of how likely success is to occur
 */
export const pOfKSuccessInNTrials = (
  kSuccesses: number,
  nTrials: number,
  pOfSuccess: number,
): number => (
               fac(nTrials)
               * Math.pow(pOfSuccess, kSuccesses)
               * Math.pow(1 - pOfSuccess, nTrials - kSuccesses)
             ) / (fac(kSuccesses) * fac(nTrials - kSuccesses));
