/**
 * Returns the difference between the two lists
 */
export const diffLists = (
  listA: string[],
  listB: string[],
): { uniqueA: string[], uniqueB: string[], common: string[] } => {
  const uniqueA = listA.filter(item => listB.indexOf(item) === -1);
  const uniqueB = listB.filter(item => listA.indexOf(item) === -1);
  const common = listA.filter(item => listB.indexOf(item) !== -1);

  return { uniqueA, uniqueB, common };
};
