import { css } from '@emotion/core';
import * as React from 'react';
import { BayesCalculator } from './BayesCalculator';
import { IterationsToCalculator } from './IterationsToCalculator';
import { NChooseRCalculator } from './NChooseRCalculator';
import { NPermuteRCalculator } from './NPermuteRCalculator';
import { POfSuccessesInTrials } from './POfSuccessesInTrials';
import { ProportionSolver } from './ProportionSolver';
import { RandomNumberGenerator } from './RandomNumberGenerator';
import { SatisfactionCalculator } from './SatisfactionCalculator';


const styles = {
  root:       css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-top: 20px;
  `,
  calculator: css`
    margin-bottom: 20px;
    margin-right: 20px;
  `,
};


/**
 * CalcPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const CalcPage: React.FC = () => (
  <div css={[ styles.root ]}>
    <ProportionSolver css={styles.calculator} />
    <RandomNumberGenerator css={styles.calculator} />
    <BayesCalculator css={styles.calculator} />
    <NChooseRCalculator css={styles.calculator} />
    <NPermuteRCalculator css={styles.calculator} />
    <POfSuccessesInTrials css={styles.calculator} />
    <IterationsToCalculator css={styles.calculator} />
    <SatisfactionCalculator css={styles.calculator} />
  </div>
);
