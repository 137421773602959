import { css } from '@emotion/core';
import { DROP_SHADOW } from '../core/styles/text';
import Color from '../core/theme/color';


export const FORM_SPACING = css`
  & > * {
    display: block;
    margin-bottom: 25px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const FORM_SPACING_HORIZ = css`
  & > * {
    display: block;
    margin-right: 25px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;


export const FORM_SPACING_SM = css`
  & > * {
    display: block;
    margin-bottom: 15px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const FORM_SPACING_HORIZ_SM = css`
  & > * {
    display: block;
    margin-right: 15px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;


export const TEXT_INPUT = css`
  font-size: 1.4rem;
  padding: 10px;
  border: solid 2px ${Color.LIGHT.INPUT_BORDER};
  border-radius: 5px;
`;


export const BUTTON = css`
  color: ${Color.LIGHT.BUTTON_TEXT};
  border-radius: 5px;
  padding: 20px 40px;
  text-decoration: none;
  background-color: ${Color.LIGHT.BUTTON_BG};
  text-align: center;
  transition: all .2s ease;
  ${DROP_SHADOW};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    opacity: 1;
    transform: translate(0, -4px);
    background-color: ${Color.LIGHT.BUTTON_BG_HOVER};
  }
`;

export const SELECT = css`
  height: 40px;
  border: solid 2px ${Color.LIGHT.INPUT_BORDER};
  border-radius: 10px;
  width: 200px;
  font-size: 1.4rem;
`;
