import { css } from '@emotion/core';
import * as React from 'react';
import { COLOR } from '../core/colors';


const style = css`
  border-radius: 10px;
  box-shadow: -2px -2px 5px #FFFFFF, 2px 2px 5px ${COLOR.SHADOW};
  padding: 20px;
`;


interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}


/**
 * Plateau: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const Plateau: React.FC<Props> = (props: Props) => (
  <div css={style} {...props} />
);
