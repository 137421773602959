import { ChangeEvent } from 'react';
import * as React from 'react';
import { allGood } from './module';


export const useAllGood = (
  values: Array<string | undefined>,
): boolean => React.useMemo(() => allGood(values), [ values ]);

export const useOnChange = (
  setFunc: (n: string) => void,
) => React.useCallback(
  (event: ChangeEvent<HTMLInputElement>) => setFunc(event.target.value),
  [ setFunc ],
);
