/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import * as R from 'ramda';
import * as React from 'react';
import { DROP_SHADOW } from '../core/styles/text';
import LabelGroup from '../forms/LabelGroup';
import { BUTTON, FORM_SPACING, SELECT, TEXT_INPUT } from '../forms/styles';


const connect = () => (f: any) => f;


/**
 * ThreadHome: a component that
 *
 * PureComponent: true
 * Connected to Redux: true
 */
export default connect(

)(class ThreadHome extends React.PureComponent<{
  location?: any;
}, {
  threadGuid: string;
}> {

  constructor(props: any) {
    super(props);

    this.state = {
      threadGuid: R.last(props.location.pathname.split('/')) || '',
    };
  }


  public render = () => (
    <div css={css`
           display: flex;
           flex-direction: column;
           align-items: stretch;
           overflow-y: auto;
           padding: 50px;
         `}>
      <h1 css={css`font-size: 2.0rem; margin: 0;`}>Thread: {this.state.threadGuid}</h1>

      <div css={css`
             background-color: white;
             border-radius: 10px;
             ${DROP_SHADOW};
             padding: 20px;
             margin: 20px 0;
             ${FORM_SPACING};
           `}>
        <h2 css={css`
              font-size: 1.8rem;
              margin: 0;
              max-width: 100%;
              overflow-x: auto;
              white-space: nowrap;
            `}>
          Some Subject
        </h2>
        <h2 css={css`font-size: 1.8rem; margin: 0;`}>Message:</h2>
        <div css={css`
               max-height: 600px;
               overflow-y: auto;
             `}>
        <span css={css`font-size: 1.4rem; `}>
          Spicy jalapeno bacon ipsum dolor amet prosciutto fatback reprehenderit proident jerky pastrami ullamco fugiat
          nostrud eiusmod dolor deserunt ut. Quis commodo ham hock ribeye eu dolore. Short ribs beef ribs rump, tempor
          turducken et drumstick lorem in biltong qui t-bone pork loin. Swine turducken pig kevin ball tip aliqua. Beef
          magna officia frankfurter veniam eiusmod flank aliquip. Boudin pork chop brisket flank incididunt duis ham
        </span>
        </div>

        <button css={css`
                  ${BUTTON};
                  font-size: 1.4rem;
                `}>Decrypt
        </button>
      </div>

      <form css={css`
              display: flex;
              flex-direction: column;
              background-color: white;
              border-radius: 10px;
              ${DROP_SHADOW};
              padding: 20px;
              margin: 20px 0;
              ${FORM_SPACING};
            `}>
        <LabelGroup label='Subject'
                    children={
                      <input css={TEXT_INPUT}/>
                    }/>

        <LabelGroup label='Message'
                    children={
                      <textarea css={css`
                        ${TEXT_INPUT}; 
                        resize: vertical;
                      `}/>
                    }/>

        <LabelGroup label='Encryption'
                    children={
                      <select css={SELECT}>
                        <option value='server-key'>Server Key</option>
                        <option value='paste-key'>Paste Key</option>
                        <option value='generate-key'>Generate Key</option>
                        <option value='none'>None</option>
                      </select>
                    }/>

        <div css={css`
          display: flex;
          flex-direction: row;
        `}>
          <button type='submit'
                  css={css`
                    ${BUTTON};
                    font-size: 1.4rem;
                  `}>Save
          </button>
        </div>

      </form>
    </div>
  );
});
