import moment from 'moment';
import { Timer, TimerInstance } from './timers';


const calcElapsed = (
  instance: TimerInstance,
): number => moment(instance.stop ?? new Date()).diff(moment(instance.start), 'seconds');

export const calcTotalElapsed = (
  timer: Timer
): number => timer.instances.reduce(
  (running, next) => running + calcElapsed(next),
  calcElapsed(timer.activeInstance),
);


export const formatLong = (seconds: number): string => moment.utc(1000 * seconds).format('HH:mm:ss');

export const toHours = (seconds: number): string => `${(seconds / 60 / 60).toFixed(2)}`;

