import { css } from '@emotion/core';
import * as React from 'react';
import { Button } from '../forms/Button';
import { useOnSubmit } from '../forms/hooks';
import { Input } from '../forms/Input';
import { Plateau } from '../forms/Plateau';
import { FORM_SPACING } from '../forms/styles';
import { bayesTheorem } from './formulas';
import { useAllGood, useOnChange } from './hooks';
import { BUTTON_ROW, CALC_INPUT, CALC_INPUTS, CLEAR_BTN, SOLUTION_TEXT, SUBMIT_BTN, TITLE } from './styles';


const styles = {
  root: css`
    max-width: 605px;
  `,
};


type Props = {
  className?: string;
};

/**
 * BayesCalculator: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
export const BayesCalculator: React.FC<Props> = (props: Props) => {
  const [ pOfA, setPOfA ]             = React.useState<string | undefined>(undefined);
  const [ pOfB, setPOfB ]             = React.useState<string | undefined>(undefined);
  const [ pOfBGivenA, setPOfBGivenA ] = React.useState<string | undefined>(undefined);
  const [ pOfAGivenB, setPOfAGivenB ] = React.useState<number | undefined>(undefined);

  const onChangePOfA = useOnChange(setPOfA);
  const onChangePOfB = useOnChange(setPOfB);
  const onChangePOfBGivenA = useOnChange(setPOfBGivenA);

  const canCalculate = useAllGood([ pOfA, pOfB, pOfBGivenA ]);

  const onClear = () => {
    setPOfA(undefined);
    setPOfB(undefined);
    setPOfBGivenA(undefined);
    setPOfAGivenB(undefined);
  };

  const onSubmit = useOnSubmit(
    () => canCalculate && setPOfAGivenB(bayesTheorem(+pOfA!, +pOfB!, +pOfBGivenA!)),
    [ setPOfAGivenB, canCalculate, bayesTheorem, pOfA, pOfB, pOfBGivenA ],
  );

  return (
    <Plateau css={styles.root} className={props.className}>
      <form css={FORM_SPACING}>
        <h4 css={TITLE}>Bayes Theorem</h4>

        <Plateau>
          <span css={SOLUTION_TEXT}>P(A | B) = {pOfAGivenB || 'N/A'}</span>
        </Plateau>

        <div css={CALC_INPUTS}>
          <Input type='number' onChange={onChangePOfA} placeholder='P(A)' css={CALC_INPUT} />
          <Input type='number' onChange={onChangePOfB} placeholder='P(B)' css={CALC_INPUT} />
          <Input type='number' onChange={onChangePOfBGivenA} placeholder='P(B | A)' css={CALC_INPUT} />
        </div>

        <div css={BUTTON_ROW}>
          <Button type='reset' css={CLEAR_BTN} onClick={onClear}>Clear</Button>
          <Button type='submit' css={SUBMIT_BTN} onClick={onSubmit}>Calculate</Button>
        </div>
      </form>
    </Plateau>
  );
};
